import React from "react";

const GdscCarousel2 = () => {
  const address = "./communityPartners/";
  const community = [
    { images: `${address}dsc-aliah.png` },
    { images: `${address}dsc-bit-deoghar.png` },
    { images: `${address}dsc-bit-mesra-jaipur.png` },
    { images: `${address}dsc-ceal.png` },
    { images: `${address}dsc-cfct.png` },
    { images: `${address}dsc-dgi.png` },
    { images: `${address}dsc-gec-bilaspur.png` },
    { images: `${address}dsc-jcmcsiit.png` },
    { images: `${address}dsc-mmdu.png` },
    { images: `${address}dsc-narula.png` },
    { images: `${address}dsc-rait.png` },
    { images: `${address}dsc-srec.png` },
    { images: `${address}dsc-sstc.png` },
    { images: `${address}dsc-tib.png` },
    { images: `${address}dsc-uit-rgpv.png` },
    { images: `${address}dsc-scet.png` },
    { images: `${address}dsc-pccer.png` },
    { images: `${address}dsc-nmims-mpstme.png` },
    { images: `${address}dsc-nit-ap.png` },
    { images: `${address}dsc-jntu-gv.png` },
    { images: `${address}dsc-gvpce.png` },
    { images: `${address}dsc-avantika.png` },
    { images: `${address}dsc-mbm-u.png` },
    { images: `${address}dsc-rec-bijnor.png` },
    { images: `${address}dsc-nit-jalandhar.png` },
    { images: `${address}dsc-rkmgec.png` },
    { images: `${address}dsc-pce.png` },
    { images: `${address}dsc-uiet-k.png` },
    { images: `${address}dsc-nit-p.png` },
  ];
  return (
    <>
      <div className="marquee">
        <div className="marquee-content-2">
          {community.map((communities) => {
            return (
              <div className="marquee-item">
                <img src={communities.images} alt="" />
              </div>
            );
          })}
          {community.map((communities) => {
            return (
              <div className="marquee-item">
                <img src={communities.images} alt="" />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default GdscCarousel2;
