/* eslint-disable */
import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";

const data = {
  title: "FAQ",
  rows: [
    {
      title: "Can I participate remotely?",
      content:
        "Hackdays is a completely online hackathon. You can attend the event from anywhere in the world as long as you have an internet connection.",
    },
    {
      title: "How much does it cost to participate?",
      content:
        "For the first 1000 applicants, it costs only ₹99 to participate. The remaining price categories are as follows:<br />1001-1500 : ₹129<br />1501-2000 : ₹149<br />2001-2500 : ₹199<br />2501+ : ₹249",
      // We'll have swags, prizes and many more cool rewards to complement the same. Plus, you'll be mentored by industry experts and gain actual work experience on how projects are developed in a matter of few days.
    },
    {
      title: "I am a beginner, can I participate?",
      content:
        "As long as you are an enthusiast for development, absolutely. We will have mentors and resources available, along with some workshops targeted for beginners. Come learn and experience your first hackathon at Hackdays.",
    },
    {
      title:
        "What is the maximum team size? Can I participate as a Solo Developer?",
      content:
        "Yes, you can participate as a Solo Developer. But, if one wants, they can participate as a team of maximum 3 members as well. All the team members have to register individually first, then after successful payment verification of all members, the team leader can register on the website as a team by checking his verification status and then following the mentioned steps.",
    },
    {
      title: "When will Hackdays start and end?",
      content:
        "Slot 1 Starts @ 9pm 10th March 2023. Ends @ 10pm 12th March 2023. <br/> Slot 2 Starts @ 9pm 17th March 2023. Ends @ 10pm 19th March 2023.",
    },
    {
      title: "When will internships be provided?",
      content: `All impressive developers at Hackdays are acknowledged here @Sellular. The crème de la crème will be getting a call from us soon after Hackdays. If anyone else is interested, he/she may apply through our <a href="https://career.sellular.club" target="_blank">career portal.</a>`,
    },
    {
      title: "Will goodies be given to everyone?",
      content:
        "YES !&nbsp; Goodies will be given to all participants who make successful code submissions approved by your mentor.",
    },
    {
      title: "What benefits do I get for participating?",
      content:
        "We'll have swags, prizes and many more cool rewards to complement Hackdays. Plus, you'll be mentored by industry experts and gain actual work experience on how projects are developed in a matter of few days.",
    },
  ],
};

const styles = {
  bgColor: "black",
  titleTextSize: "3rem",
  titleTextColor: "white",
  rowTitleColor: "white",
  rowContentColor: "#1ebaa3",
  arrowColor: "#1ebaa3",
};

const config = {
  animate: true,
  tabFocus: true,
  expandIcon: "+",
  collapseIcon: "-",
};

const Freq = () => {
  return (
    <div id="faq" className="freq-wrapper">
      <Faq data={data} styles={styles} config={config} />
    </div>
  );
};

export default Freq;
