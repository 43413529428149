function Footer() {
  return (
    <footer id="footer" className="footer">
      <div className="footer-img">
        <a
          href="http://sellular.club"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="main-logo.png" alt="sellular-logo" />
        </a>
        <h2>
          <a
            href="mailto:help.sellular@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa fa-envelope"></i> hello.sellular@gmail.com
          </a>
        </h2>
      </div>
      <div className="vertical-line"></div>

      <div className="links">
        <ul className="footer-links">
          <li className="footer-link">
            <a
              className="footer-link-a"
              href="https://sellular.club/PrivacyPolicy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </li>
          <li className="footer-link">
            <a
              className="footer-link-a"
              href="https://sellular.club/TermsAndCondition"
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </a>
          </li>
          <li className="footer-link">
            <a
              className="footer-link-a"
              href="https://sellular.club"
              target="_blank"
              rel="noreferrer"
            >
              About Us
            </a>
          </li>
          <li className="footer-link">
            <a
              className="footer-link-a"
              href="https://sellular.club/CancellationRefund"
              target="_blank"
              rel="noreferrer"
            >
              Refund Policy
            </a>
          </li>
        </ul>
        <ul className="social-icons">
          <li className="social-icons-list">
            <a
              className="whatsapp social-icons-list-a"
              href="http://bit.ly/3jcKlAh"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-whatsapp"></i>
            </a>
          </li>
          <li className="social-icons-list">
            <a
              className="discord social-icons-list-a"
              href="https://discord.gg/mZhf8hTBk5"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-discord"></i>
            </a>
          </li>
          <li>
            <a
              className="twitter social-icons-list-a"
              href="/#"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-twitter"></i>
            </a>
          </li>
          <li>
            <a
              className="instagram social-icons-list-a"
              href="https://www.instagram.com/sellular_networks/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-instagram"></i>
            </a>
          </li>
          <li>
            <a
              className="linkedin social-icons-list-a"
              href="https://www.linkedin.com/company/sellularnetworks/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-linkedin"></i>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
