import axios from "axios";
import React from "react";
import { useState } from "react";
import swal from "sweetalert";
import { validEmail, phoneNumber } from "./RegEx.js";
import Navbar2 from "./Navbar2";
function RegisterTeam() {
  const [team, setTeam] = useState({
    TeamName: "",
    LeaderName: "",
    LeaderEmail: "",
    LeaderPhone: "",
    Member2: "",
    Member2Email: "",
    Member2Phone: "",
    Member3: "",
    Member3Email: "",
    Member3Phone: "",
  });
  const submitHandler = async () => {
    if (team.TeamName === "") {
      swal({
        title: "Team name cannot be empty",
        icon: "warning",
        className: "black-bg",
      });
      return;
    } else if (team.LeaderName === "") {
      swal({
        title: "Leader name cannot be empty",
        icon: "warning",
        className: "black-bg",
      });
      return;
    } else if (team.LeaderEmail === "") {
      swal({
        title: "Leader email cannot be empty",
        icon: "warning",
        className: "black-bg",
      });
      console.log("first");
      return;
    } else if (!validEmail.test(team.LeaderEmail)) {
      swal({
        title: "Leader email is invalid",
        icon: "warning",
        className: "black-bg",
      });
      return;
    } else if (team.LeaderEmail) {
      const respLeaderEmail = await axios.post(
        "https://api.sellular.club/payment/verification-status",
        { email: team.LeaderEmail }
      );
      console.log(respLeaderEmail.data.status);
      if (respLeaderEmail.data.status) {
        if (team.LeaderPhone === "") {
          swal({
            title: "Leader phone cannot be empty",
            icon: "warning",
            className: "black-bg",
          });
          return;
        } else {
          if (!phoneNumber.test(team.LeaderPhone)) {
            swal({
              title: "Leader phone is invalid",
              icon: "warning",
              className: "black-bg",
            });
            return;
          } else {
            if (team.Member2 === "") {
              swal({
                title: "Member 2 name cannot be empty",
                icon: "warning",
                className: "black-bg",
              });
              return;
            } else if (!validEmail.test(team.Member2Email)) {
              swal({
                title: "Member 2 email is invalid",
                icon: "warning",
                className: "black-bg",
              });
              return;
            } else if (!phoneNumber.test(team.Member2Phone)) {
              swal({
                title: "Member 2 Phone Number is invalid",
                icon: "warning",
                className: "black-bg",
              });
              return;
            } else {
              if (team.Member3) {
                if (team.Member3Email === "") {
                  swal({
                    title: "Member 3 email cannot be empty",
                    icon: "warning",
                    className: "black-bg",
                  });
                  return;
                } else if (!validEmail.test(team.Member3Email)) {
                  swal({
                    title: "Member 3 email is invalid",
                    icon: "warning",
                    className: "black-bg",
                  });
                  return;
                } else if (!phoneNumber.test(team.Member3Phone)) {
                  swal({
                    title: "Member 3 Phone is invalid",
                    icon: "warning",
                    className: "black-bg",
                  });
                  return;
                } else {
                  const data = {
                    TeamName: team.TeamName,
                    LeaderName: team.LeaderName,
                    LeaderEmail: team.LeaderEmail,
                    LeaderPhone: team.LeaderPhone,
                    Member2: team.Member2,
                    Member2Email: team.Member2Email,
                    Member2Phone: team.Member2Phone,
                    Member3: team.Member3,
                    Member3Email: team.Member3Email,
                    Member3Phone: team.Member3Phone,
                  };
                  axios
                    .post("https://sheetdb.io/api/v1/9awl4dqfw4hov", data)
                    .then(
                      swal({
                        title: "Successfully Registered",
                        icon: "success",
                      }).then(function () {
                        window.location.assign("/");
                      })
                    );
                }
              } else {
                const data = {
                  TeamName: team.TeamName,
                  LeaderName: team.LeaderName,
                  LeaderEmail: team.LeaderEmail,
                  LeaderPhone: team.LeaderPhone,
                  Member2: team.Member2,
                  Member2Email: team.Member2Email,
                  Member2Phone: team.Member2Phone,
                  Member3: team.Member3,
                  Member3Email: team.Member3Email,
                  Member3Phone: team.Member3Phone,
                };
                axios
                  .post("https://sheetdb.io/api/v1/9awl4dqfw4hov", data)
                  .then(
                    swal({
                      title: "Successfully Registered",
                      icon: "success",
                    }).then(function () {
                      window.location.assign("/");
                    })
                  );
              }
            }
          }
        }
        return;
      }
    }
  };
  return (
    <>
      <Navbar2 />
      <div className="Register-Team-Form-Wrapper">
        <div className="Register-Team-Form">
          <div className="Register-Team-Form-heading">Team Registration</div>
          <div className="Register-Team-Form-Container">
            <input
              type="text"
              placeholder="Team Name"
              onChange={(e) => setTeam({ ...team, TeamName: e.target.value })}
            />
          </div>
          <div className="Register-Team-Form-Container">
            <div className="Register-Team-Form-Label">Lead Details:</div>
            <div className="Register-Team-Form-LeaderDetails">
              <input
                type="text"
                name=""
                placeholder="Name"
                onChange={(e) =>
                  setTeam({ ...team, LeaderName: e.target.value })
                }
              />
              <input
                type="email"
                name=""
                placeholder="Email"
                onChange={(e) =>
                  setTeam({ ...team, LeaderEmail: e.target.value })
                }
              />
              <input
                type="number"
                name=""
                placeholder="Phone Number"
                onChange={(e) =>
                  setTeam({ ...team, LeaderPhone: e.target.value })
                }
              />
            </div>
            <div className="Register-Team-Form-MemberDetails">
              <div className="Register-Team-Form-Label">Members Details:</div>
              <div className="Register-Team-Form-headings">Member 2:</div>
              <input
                type="text"
                placeholder="Member 2"
                onChange={(e) => setTeam({ ...team, Member2: e.target.value })}
              />
              <input
                type="email"
                placeholder="Member 2 Email"
                onChange={(e) =>
                  setTeam({ ...team, Member2Email: e.target.value })
                }
              />
              <input
                type="number"
                placeholder="Member 2 Phone Number"
                onChange={(e) =>
                  setTeam({ ...team, Member2Phone: e.target.value })
                }
              />
              <div className="Register-Team-Form-headings">Member 3:</div>
              <input
                type="text"
                placeholder="Member 3"
                onChange={(e) => setTeam({ ...team, Member3: e.target.value })}
              />
              <input
                type="email"
                placeholder="Member 3 Email"
                onChange={(e) =>
                  setTeam({ ...team, Member3Email: e.target.value })
                }
              />
              <input
                type="number"
                placeholder="Member 3 Phone Number"
                onChange={(e) =>
                  setTeam({ ...team, Member3Phone: e.target.value })
                }
              />
              <div className="warning-text">
                <span>*</span>All Team Members need to register individually
                before Team Registration
              </div>
            </div>
          </div>
          <button onClick={submitHandler}>Submit</button>
        </div>
      </div>
    </>
  );
}
export default RegisterTeam;
