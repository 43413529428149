import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Hero from "./Hero";
import Awards from "./Awards";
import RegisterAndCheck from "./RegisterAndCheck";
import Roadmap from "./Roadmap";
import CommunityPartners from "./CommunityPartners";
import Footer from "./Footer";
import Freq from "./Faq";
import "./styles.css";
function Home() {
  useEffect(() => {
    window.VANTA.NET({
      el: ".hero",
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
      color: 0x1ebaa3,
      backgroundColor: 0x000000,
    });
  });
  useEffect(() => {
    window.VANTA.GLOBE({
      el: ".register-and-check-wrapper",
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
      color: 0x1ebaa3,
      color2: 0x000000,
      size: 0.5,
      backgroundColor: 0x000000,
    });
  });
  useEffect(() => {
    window.VANTA.DOTS({
      el: "#community-partners",
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
      color: 0x1ebaa3,
      color2: 0x1ebaa3,
      backgroundColor: 0x000000,
      showLines: false,
    });
  });
  return (
    <div>
      <Navbar />
      <Hero />
      <Awards />
      <RegisterAndCheck />
      <Roadmap />
      <CommunityPartners />
      <Freq />
      <Footer />
    </div>
  );
};

export default Home;
