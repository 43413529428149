import React from "react";
import GdscCarousel1 from "./GdscCarousel1";
import GdscCarousel2 from "./GdscCarousel2";
import Communities from "./Communities";

const CommunityPartners = () => {
  return (
    <>
      <div id="community-partners" className="community-partners-container">
        <h2 className="community-partners-heading">Community Partners</h2>
        <Communities />
        <GdscCarousel1 />
        <GdscCarousel2 />
      </div>
    </>
  );
};

export default CommunityPartners;
