/* eslint-disable */
import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./components/styles.css";
// import CheckStatus from "./components/CheckStatus";
import Home from "./components/Home";
import RegisterTeam from "./components/RegisterTeam";
// import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  console.log(<RegisterTeam/>)
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/TeamRegister" element={<RegisterTeam/>}/>
        </Routes>
      </BrowserRouter>
      <>
      </>
    </div>
  );
}

export default App;
