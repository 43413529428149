function Roadmap() {
  return (
    <div id="roadmap-wrapper" className="roadmap-wrapper">
      <div className="roadmap-container">
        <div className="roadmap-heading">Roadmap</div>
        <div className="roadmap-wrapper-row-one">
          <div className="cards">
            <div className="card-step">Step 1</div>
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img className="card-img" src="registration.gif" alt="" />
                  <h2 className="card-title">Register</h2>
                </div>
                <div className="flip-card-back">
                  <p className="card-description">
                    Till<br></br> 5pm, 9th March 2023
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="cards">
            <div className="card-step">Step 2</div>
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img className="card-img" src="approved.gif" alt="" />
                  <h2 className="card-title">Application Approved</h2>
                </div>
                <div className="flip-card-back">
                  <p className="card-description">Only for Enthusiasts</p>
                </div>
              </div>
            </div>
          </div>
          <div className="cards">
            <div className="card-step">Step 3</div>
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img className="card-img" src="mentorass.gif" alt="" />
                  <h2 className="card-title">Mentor Assigned</h2>
                </div>
                <div className="flip-card-back">
                  <p className="card-description">
                    On-Spot Topic Given by the Mentor for Development
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="cards">
            <div className="card-step">Step 4</div>
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img className="card-img" src="hackdays.gif" alt="" />
                  <h2 className="card-title">HACKDAYS</h2>
                </div>
                <div className="flip-card-back">
                  <p className="card-description">
                    Starts at 9pm 10th March 2023 <br /> Ends at 10pm 12th March
                    2023
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="cards">
            <div className="card-step">Step 5</div>
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img className="card-img" src="goodies.gif" alt="" />
                  <h2 className="card-title">Goodies & Rewards</h2>
                </div>
                <div className="flip-card-back">
                  <p className="card-description">
                    Goodies & Rewards distributed to winners
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Roadmap;
