import React from "react";

const Communities = () => {
  const address = "./communityPartners/comms/";
  const communities = [
    { images: `${address}acm-w-jmi.png` },
    { images: `${address}bubbleup-1.png` },
    { images: `${address}codedu.png` },
    { images: `${address}coders-evoke-1.png` },
    { images: `${address}ieee-cs-cu.png` },
    { images: `${address}sos-tech-1.png` },
    { images: `${address}we-build-pune.png` },
    { images: `${address}adc.png` },
    { images: `${address}devstation.png` },
    { images: `${address}acm-iit-h.png` },
    { images: `${address}ignite.png` },
    { images: `${address}intel-iot-amrita.jpeg` },
    { images: `${address}ieee-nhce.png` },
    { images: `${address}e-club-iiit-bhagalpur.jpeg` },
    { images: `${address}quantum-srm.svg` },
  ];
  return (
    <>
      <div className="communities-carousel-wrapper">
        <h2 className="marquee-3-heading">Communities</h2>
        <div className="community-marquee">
          <div className="sellular-marquee-logo-wrapper">
            <img
              src="./communityPartners/comms/sellular-logo.svg"
              alt="sellular"
              className="sellular-marquee-logo"
            ></img>
            <div className="sellular-marquee-logo-text">Sellular</div>
          </div>
          <div className="marquee">
            <div className="marquee-content-3">
              {communities.map((e) => {
                return (
                  <div className="marquee-item marquee-item-2">
                    <img src={e.images} alt="" />
                  </div>
                );
              })}
              {communities.map((e) => {
                return (
                  <div className="marquee-item marquee-item-2">
                    <img src={e.images} alt="" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Communities;
