import React from "react";

const gdscCarousel1 = () => {
  const address = "./communityPartners/";
  const community = [
    { images: `${address}dsc-nie.png` },
    { images: `${address}dsc-abes.png` },
    { images: `${address}dsc-abesit.png` },
    { images: `${address}dsc-adypu.png` },
    { images: `${address}dsc-aec.png` },
    { images: `${address}dsc-gec-patan.png` },
    { images: `${address}dsc-gkdu.png` },
    { images: `${address}dsc-gni.png` },
    { images: `${address}dsc-gzsccet.png` },
    { images: `${address}dsc-iare.png` },
    { images: `${address}dsc-iiit-agartala.png` },
    { images: `${address}dsc-iiit-kota.png` },
    { images: `${address}dsc-iiit-kalyani.png` },
    { images: `${address}dsc-jspm-jsce.png` },
    { images: `${address}dsc-kkcem.png` },
    { images: `${address}dsc-nce.png` },
    { images: `${address}dsc-pdeu.png` },
    { images: `${address}dsc-providence.png` },
    { images: `${address}dsc-pu.png` },
    { images: `${address}dsc-sdsf-davv.png` },
    { images: `${address}dsc-skct.png` },
    { images: `${address}dsc-snpit.png` },
    { images: `${address}dsc-vishwaniketan.png` },
    { images: `${address}dsc-vssut.png` },
    { images: `${address}dsc-sies-gst.png` },
    { images: `${address}dsc-jiit-128.png` },
    { images: `${address}dsc-gpcet.png` },
    { images: `${address}dsc-cu.png` },
    { images: `${address}dsc-nit-goa.png` },
    { images: `${address}dsc-bitw.png` },
  ];
  return (
    <>
      <h2 className="marquee-1-heading">Google Developer Student Clubs</h2>
      <div className="marquee">
        <div className="marquee-content-1">
          {community.map((communities) => {
            return (
              <div className="marquee-item">
                <img src={communities.images} alt="" />
              </div>
            );
          })}
          {community.map((communities) => {
            return (
              <div className="marquee-item">
                <img src={communities.images} alt="" />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default gdscCarousel1;
