import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";

function Navbar() {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <header className="navbar">
      <a className="nav-logo-wrapper" href="https://sellular.club">
        <img className="nav-logo" src="sellular-logo.png" alt="sellular-logo" />
        <div className="nav-logo-heading">Sellular</div>
      </a>
      <nav ref={navRef}>
        <a
          href="https://hackdays.sellular.club/#cool-rewards"
          onClick={showNavbar}
        >
          Cool Rewards
        </a>
        <a
          href="https://hackdays.sellular.club/#roadmap-wrapper"
          onClick={showNavbar}
        >
          Roadmap
        </a>
        <a
          href="https://hackdays.sellular.club/#community-partners"
          onClick={showNavbar}
        >
          Community Partners
        </a>
        <a href="https://hackdays.sellular.club/#faq" onClick={showNavbar}>
          FAQ
        </a>
        <a
          href="https://hackdays.sellular.club/#registration"
          onClick={showNavbar}
        >
          Register Now
        </a>
        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <FaTimes />
        </button>
      </nav>
      <button className="nav-btn" onClick={showNavbar}>
        <FaBars />
      </button>
    </header>
  );
}

export default Navbar;
